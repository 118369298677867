<script>
import { layoutComputed } from "@/state/helpers";

import Vertical from "./vertical";
import Horizontal from "./horizontal";
import TwoColumns from "./twocolumn";

export default {
    props: {
        onCompanyGlobalFilterChange: {
            type: Function,
            required: false
        }
    },
    components: {
        Vertical,
        Horizontal,
        TwoColumns
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
    },
};
</script>

<template>
    <div>
        <Vertical v-if="layoutType === 'vertical'" :layout="layoutType" :onCompanyGlobalFilterChange="$props.onCompanyGlobalFilterChange">
            <slot />
        </Vertical>

        <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType" :onCompanyGlobalFilterChange="$props.onCompanyGlobalFilterChange">
            <slot />
        </Horizontal>

        <TwoColumns v-if="layoutType === 'twocolumn'" :layout="layoutType" :onCompanyGlobalFilterChange="$props.onCompanyGlobalFilterChange">
            <slot />
        </TwoColumns>
    </div>
</template>
