<script>

export default {
  props: {
    job: {
      type: Object,
      required: true
    },
    supportClose: {
      type: Boolean,
      default: true
    }
  },
};
</script>

<template>
    <div class="">
        <div class="d-flex justify-content-between">
            <div :class="['d-flex gap-1 align-items-center',
                job.status === 'failed' ? 'text-danger' : 
                job.status === 'done' ? 'text-success' :
                job.status === 'running' ? 'text-secondary' : ''
            ]">
                <i v-if="job.status === 'done'" class="mdi mdi-check-circle-outline fs-16 not-opacity"></i> 
                <i v-if="job.status === 'failed'" class="mdi mdi-alert-circle-outline fs-16 not-opacity"></i>
                <i v-if="job.status === 'running'" class="mdi mdi-clock-time-four-outline fs-16 not-opacity"></i>
                <h6 class="mb-0 not-opacity font-weight-bold" >{{job.title}}</h6>
            </div>
            <button v-if="supportClose" type="button" class="btn-close clickable not-opacity" aria-label="Close" @click="$store.commit('asyncJobs/updateJob', {...job, show: false})"></button>
        </div>
        <div class="">
            <h6 class="mt-0 mb-2 lh-base not-opacity">
                {{job.message}} <br>
            </h6>
            <p class="mb-0 fs-11 fw-medium text-uppercase text-muted not-opacity">
                {{job.date}}
            </p>
        </div>
    </div>
</template>

<style scoped>
.container::-webkit-scrollbar {
    display: none;
}
.container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.clickable {
  pointer-events: auto; /* Elementos internos que ainda podem ser clicados */
}
.not-opacity{
  opacity: 1;
}
</style>