<template>
<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{new Date().getFullYear()}} © Apoio.
            </div>
        </div>
    </div>
</footer>
</template>
