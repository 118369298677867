<script>
import {
  SimpleBar
} from "simplebar-vue3";
import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.min.css';
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import { DateTime } from "luxon"
import { ref } from "vue";
import i18n from "../i18n";
import router from '../router/index';
import BackgroundJobsNotification from './background_jobs_notification';
import Job from "./background_jobs_notification/job.vue";
import { useStore } from "vuex";
import { userService } from '@/helpers/authservice/user.service'

export default {
  props: {
    onCompanyGlobalFilterChange: {
      type: Function,
      required: false
    }
  },
  setup(props) {
    const store = useStore()
    const recentSearches = localStorage.getItem('app_recentSearches') ? JSON.parse(localStorage.getItem('app_recentSearches')) : [];
    const searchValue = ref('');
    const sis_config = JSON.parse(localStorage.getItem('sis_config'));
    const sis_usuario = sis_config.sis_usuario;
    const modules = sis_config.sis_configuracao_sistema.sis_modulo_sistema ?? [];
    const companies = sis_config.sis_empresa ?? [];
    const searchResults = ref([]);
    const displayableCompanies = ref([]);
    const checkAllCompanies = ref(true);
    const filter = ref({
      date: Date.now(),
    })
    const checkedCompaniesIds = JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?
      ref(JSON.parse(localStorage.getItem('companiesGlobalFilter'))) :
      ref([]);
    /** when page start, set the companies that is already checked in localStorage companiesGlobalFilter */
    companies.map(dc => displayableCompanies.value.push({ ...dc, match: true, checked: checkedCompaniesIds.value.includes(dc.id_empresa) }))
    displayableCompanies.value.map(dc => dc.checked == false ? checkAllCompanies.value = false : false)

    localStorage.setItem('dataTrabalho', JSON.stringify(new Date().toISOString().split('T')[0]))

    const alwaysShowActions = localStorage.getItem('alwaysShowInScreen') ? JSON.parse(localStorage.getItem('alwaysShowInScreen')) : true
    store.commit('asyncJobs/setAlwaysShowInScreen', alwaysShowActions)

    const setAlwaysShowActions = (e) => {
      localStorage.setItem('alwaysShowInScreen', e.target.checked)
      store.commit('asyncJobs/setAlwaysShowInScreen', e.target.checked)
    }

    function addToRecentSearches(moduloId, arvoreId, progId) {
      const mIndex = modules.findIndex(m => m.id_modulo_sistema == moduloId);
      const amIndex = modules[mIndex].sis_arvore_menu.findIndex(am => am.id_arvore_menu == arvoreId);
      const progIndex = modules[mIndex].sis_arvore_menu[amIndex].sis_programa.findIndex(sp => sp.id_programa == progId);
      const rs = { m: mIndex, a: amIndex, p: progIndex };
      if (recentSearches.findIndex(a => a.m == rs.m && a.a == rs.a && a.p == rs.p) == -1) {
        recentSearches.unshift(rs);
        if (recentSearches.length > 3) {
          recentSearches.splice(3, recentSearches.length);
        }
        localStorage.setItem('app_recentSearches', JSON.stringify(recentSearches));
      }
    }

    function searchInMenuThree() {
      searchResults.value = [];
      const tempArray = [];
      const stringMatch = searchValue.value.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "");

      modules.map((m, index) => {
        let resObj = {};
        if (m.denominacao.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "").includes(stringMatch)) {
          resObj = m;
        } else {
          m.sis_arvore_menu.map((aM, aMindex) => {
            if (aM.denominacao.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "").includes(stringMatch)) {
              if (resObj.modulo) {
                resObj.sis_arvore_menu.push(aM);
              } else {
                resObj = { ...modules[index], sis_arvore_menu: [] };
                resObj.sis_arvore_menu.push(aM);
              }
            } else {
              aM.sis_programa.map((sP) => {
                if (sP.denominacao.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "").includes(stringMatch)) {
                  if (resObj.sis_arvore_menu) {
                    if (resObj.sis_arvore_menu[aMindex]) {
                      if (resObj.sis_arvore_menu[aMindex].sis_programa) {
                        resObj.sis_arvore_menu[aMindex].sis_programa.push(sP);
                      } else {
                        resObj.sis_arvore_menu[aMindex].sis_programa = [sP];
                      }
                    } else {
                      resObj.sis_arvore_menu[aMindex] = { ...aM, sis_programa: [sP] }
                    }
                  } else {
                    resObj = { ...modules[index], sis_arvore_menu: [{ ...aM, sis_programa: [sP] }] };
                  }
                }
              })
            }
          })
        }
        tempArray.push(resObj);
      })
      searchResults.value = tempArray.filter(t => t.denominacao);
    }

    function filterCompaniesByName(e) {
      const name = e.target.value;
      if (name.length == 0) {
        return displayableCompanies.value.map(c => c.match = true);
      }

      displayableCompanies.value.map(c => {
        c.nome.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "").includes(name.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "")) ? c.match = true : c.match = false;
      });
    }

    function saveCompaniesFilter() {
      if (checkedCompanie(displayableCompanies.value).length == 0) {
        localStorage.setItem('companiesGlobalFilter', JSON.stringify([]))
      } else {
        localStorage.setItem('companiesGlobalFilter', JSON.stringify(checkedCompanie(displayableCompanies.value).map(c => c.id_empresa)));

        if (props.onCompanyGlobalFilterChange) {
          props.onCompanyGlobalFilterChange();
        }
      }
    }

    function saveDateFilter() {
      localStorage.setItem('dataTrabalho', JSON.stringify(filter.value.date))
    }

    /** Return a array of checked companies */
    function checkedCompanie(companiesArray) {
      return companiesArray.filter(c => c.checked)
    }

    /** Return a array of not checked companies */
    function notCheckedCompanie(companiesArray) {
      return companiesArray.filter(c => !c.checked)
    }

    function selectAllCompanies() {
      if (checkAllCompanies.value) {
        displayableCompanies.value.map(dc => dc.checked = true);
        return saveCompaniesFilter();
      }
      displayableCompanies.value.map(dc => dc.checked = false);
      return saveCompaniesFilter();
    }

    return {
      setAlwaysShowActions,
      router,
      sis_usuario,
      selectAllCompanies,
      checkAllCompanies,
      checkedCompanie,
      notCheckedCompanie,
      saveCompaniesFilter,
      saveDateFilter,
      filterCompaniesByName,
      companies,
      displayableCompanies,
      recentSearches,
      modules,
      addToRecentSearches,
      searchResults,
      searchInMenuThree,
      searchValue,
      languages: [
        {
          flag: require("@/assets/images/flags/br.svg"),
          language: "pt-br",
          title: "Português",
        },
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      myVar: 1,
      filter
    };
  },
  components: {
    SimpleBar,
    BackgroundJobsNotification,
    Job,
    flatPickr
  },
  data() {
    return {
      DateTime,
      config: {
        mode: "single",
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d/m/Y",
        enableTime: false,
        allowInput: true,
        locale: Portuguese,
      }
    }
  },
  methods: {
    toLockScreen() {
      router.push({
        name: 'lock-screen-basic', query:
        {
          redirectFrom: router.currentRoute.value.fullPath,
        }
      });
    },
    isCustomDropdown() {
      //Search bar
      var searchOptions = document.getElementById("search-close-options");
      var searchCompanyOptions = document.getElementById("search-company-close-options");
      var dropdown = document.getElementById("search-dropdown");
      var searchInput = document.getElementById("search-options");
      var companiesSearchInput = document.getElementById("search-company-options");
      var checkAll = document.getElementById('checkAllCompaniesContainer');

      if (!searchOptions || !searchCompanyOptions || !dropdown || !searchInput || !companiesSearchInput || !checkAll) return

      searchInput.addEventListener("focus", () => {
        var inputLength = this.searchValue.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });
      companiesSearchInput.addEventListener("focus", () => {
        var inputLength = companiesSearchInput.value.length;
        if (inputLength > 0) {
          searchCompanyOptions.classList.remove("d-none");
        } else {
          searchCompanyOptions.classList.add("d-none");
        }
      });

      searchInput.addEventListener("keyup", () => {
        var inputLength = this.searchValue.length;
        if (inputLength > 0) {
          this.searchInMenuThree();
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });
      companiesSearchInput.addEventListener("keyup", () => {
        var inputLength = companiesSearchInput.value.length;
        if (inputLength > 0) {
          searchCompanyOptions.classList.remove("d-none");
          checkAll.classList.add("d-none")
        } else {
          searchCompanyOptions.classList.add("d-none");
          checkAll.classList.remove("d-none")
        }
      });

      searchOptions.addEventListener("click", () => {
        this.searchValue = "";
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      });
      searchCompanyOptions.addEventListener("click", () => {
        companiesSearchInput.value = "";
        companiesSearchInput.dispatchEvent(new Event('keyup'));
        searchCompanyOptions.classList.add("d-none");
      });

      document.body.addEventListener("click", (e) => {
        if (e.target.getAttribute("id") !== "search-options") {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });
    },
    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
        document.documentElement.getAttribute("data-layout") === "horizontal"
      ) {
        document.body.classList.contains("menu") ?
          document.body.classList.remove("menu") :
          document.body.classList.add("menu");
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm" ?
            document.documentElement.setAttribute("data-sidebar-size", "") :
            document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "lg" ?
            document.documentElement.setAttribute("data-sidebar-size", "sm") :
            document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body.classList.contains("twocolumn-panel") ?
          document.body.classList.remove("twocolumn-panel") :
          document.body.classList.add("twocolumn-panel");
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    async updatePermissions() {
      const user = JSON.parse(localStorage.getItem('user'))
      const res = await userService.refresh(user.token)
      localStorage.setItem('sis_config', JSON.stringify(res))
      window.dispatchEvent(new Event('sisConfigUpdated'));
    },
    toggleDarkMode() {
      // Obtém o modo atual
      const currentMode = document.documentElement.getAttribute("data-layout-mode");

      // Alterna entre os modos
      if (currentMode === "dark") {
        // Define o modo claro
        this.$store.commit('layout/CHANGE_MODE', 'light');
        document.documentElement.setAttribute("data-layout-mode", "light");
        localStorage.setItem("data_layout_mode", "light");
      } else {
        // Define o modo escuro
        this.$store.commit('layout/CHANGE_MODE', 'dark');
        document.documentElement.setAttribute("data-layout-mode", "dark");
        localStorage.setItem("data_layout_mode", "dark");
      }
    },
    initTopbarComponents() {
      function updateCartPrice() {
        var currencySign = "$";
        var subtotal = 0;
        Array.from(document.getElementsByClassName("cart-item-price")).forEach(function (e) {
          subtotal += parseFloat(e.innerHTML);
        });
        if (document.getElementById("cart-item-total")) {
          document.getElementById("cart-item-total").innerHTML = currencySign + subtotal.toFixed(2);
        }
      }
      if (document.getElementsByClassName("dropdown-item-cart")) {
        var dropdownItemCart = document.querySelectorAll(".dropdown-item-cart").length;
        Array.from(document.querySelectorAll("#page-topbar .dropdown-menu-cart .remove-item-btn")).forEach(function (item) {
          item.addEventListener("click", function () {
            dropdownItemCart--;
            this.closest(".dropdown-item-cart").remove();
            Array.from(document.getElementsByClassName("cartitem-badge")).forEach(function (e) {
              e.innerHTML = dropdownItemCart;
            });
            updateCartPrice();
            if (document.getElementById("empty-cart")) {
              document.getElementById("empty-cart").style.display = dropdownItemCart == 0 ? "block" : "none";
            }
            if (document.getElementById("checkout-elem")) {
              document.getElementById("checkout-elem").style.display = dropdownItemCart == 0 ? "none" : "block";
            }
          });
        });
        Array.from(document.getElementsByClassName("cartitem-badge")).forEach(function (e) {
          e.innerHTML = dropdownItemCart;
        });
        if (document.getElementById("empty-cart")) {
          document.getElementById("empty-cart").style.display = "none";
        }
        if (document.getElementById("checkout-elem")) {
          document.getElementById("checkout-elem").style.display = "block";
        }
        updateCartPrice();
      }

      // notification messages
      if (document.getElementsByClassName("notification-check")) {
        Array.from(document.querySelectorAll(".notification-check input")).forEach(function (element) {
          element.addEventListener("click", function (el) {
            el.target.closest(".notification-item").classList.toggle("active");
          });
        });
      }
    }
  },
  computed: {
    runningJobs() {
      return this.$store.getters['asyncJobs/runningJobs']
    },
    allJobs() {
      return this.$store.getters['asyncJobs/jobs']
    },
    alwaysShowActions() {
      return this.$store.getters['asyncJobs/alwaysShowInScreen']
    },
  },
  mounted() {
    document.addEventListener("scroll", function () {
      var pageTopbar = document.getElementById("page-topbar");
      if (pageTopbar) {
        document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ? pageTopbar.classList.add(
          "topbar-shadow") : pageTopbar.classList.remove("topbar-shadow");
      }
    });
    if (document.getElementById("topnav-hamburger-icon"))
      document
        .getElementById("topnav-hamburger-icon")
        .addEventListener("click", this.toggleHamburgerMenu);

    this.isCustomDropdown();
    this.initTopbarComponents();

  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logos/logoAzulSemS.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logos/logoAzulSemS.png" alt="" height="17" />
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logos/logoAzulSemS.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logos/logoAzulSemS.png" alt="" height="17" />
              </span>
            </router-link>
          </div>

          <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger "
            id="topnav-hamburger-icon">
            <span class="hamburger-icon">
              <span />
              <span />
              <span />
            </span>
          </button>

          <!-- <form class="app-search d-none d-md-block">
            <div class="position-relative">
              <input v-model="searchValue" type="text" class="form-control" placeholder="Buscar..." autocomplete="off"
                id="search-options" />
              <span class="mdi mdi-magnify search-widget-icon" />
              <span class="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                id="search-close-options" />
            </div>
            <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
              <SimpleBar data-simplebar style="max-height: 480px">
                <div v-if="recentSearches" class="dropdown-header">
                  <h6 class="text-overflow text-muted mb-0 text-uppercase">
                    Buscas Recentes <i class="ri-time-fill align-bottom ms-1" />
                  </h6>
                </div>


                <div class="dropdown-item bg-transparent d-flex flex-wrap gap-1">
                  <router-link v-for="rs, index in recentSearches" :key="index"
                    :to="modules[rs.m].rota_backend + modules[rs.m].sis_arvore_menu[rs.a].rota_backend + modules[rs.m].sis_arvore_menu[rs.a].sis_programa[rs.p].rota_backend + '/' + modules[rs.m].sis_arvore_menu[rs.a].sis_programa[rs.p].id_programa"
                    class="btn btn-sm btn-soft-info mr-1"
                    :title="modules[rs.m].sis_arvore_menu[rs.a].sis_programa[rs.p].descricao">
                    {{ modules[rs.m].sis_arvore_menu[rs.a].sis_programa[rs.p].denominacao }}
                  </router-link>
                </div>
                
                <template v-for="modulo in searchResults" :key="modulo.id_modulo_sistema">
                  <div class="dropdown-header mt-2 pb-0">
                    <h6 class="text-muted mb-0 pb-0 text-overflow text-uppercase fs-13">{{ modulo.modulo }}</h6>
                  </div>
                  <template v-for="am in modulo.sis_arvore_menu" :key="modulo.id_modulo_sistema+am.id_arvore_menu">
                    <div class="dropdown-header">
                      <h6 class="text-muted ps-2 mb-0 text-overflow text-uppercase fs-12">{{ am.denominacao }}</h6>
                    </div>
                    <template v-for="sp in am.sis_programa"
                      :key="modulo.id_modulo_sistema+am.id_arvore_menu+sp.id_programa">
                      <router-link
                        @click="() => addToRecentSearches(modulo.id_modulo_sistema, am.id_arvore_menu, sp.id_programa)"
                        :to="{ path: modulo.rota_backend + am.rota_backend + sp.rota_backend + '/' + sp.id_programa, force: true }"
                        class="ps-4 dropdown-item notify-item" :title="sp.descricao">
                        <i class="ri-bubble-chart-line align-middle fs-18 text-muted me-2" />
                        <span>{{ sp.denominacao }}</span>
                      </router-link>
                    </template>
                  </template>
                </template>
              </SimpleBar>
            </div>
          </form> -->

          <div class="ms-1 header-item d-none d-sm-flex position-relative" title="Selecione a Data de Trabalho">
            <flat-pickr v-model="filter.date" class="flatpickr-input border-1 rounded"
              placeholder="Selecione a Data Padrão" :config="config" @change="saveDateFilter" />
            <i class="mdi mdi-calendar-blank-outline fs-22"
              style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); pointer-events: none; color: #948E99;" />
          </div>

          <div class="dropdown topbar-head-dropdown ms-1 header-item" @click.stop>
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Filtro de empresas">
              <i class="bx bx-buildings fs-22" />
              <span class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-info"
                :title="checkedCompanie(displayableCompanies).length + ' Empresas selecionadas'">{{
                checkedCompanie(displayableCompanies).length }}</span>
            </button>
            <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-start" id="companies-filter-dropdown">
              <div class="p-3 pb-0 border-top-0 border-start-0 border-end-0 border-dashed border">
                <div class="row align-items-center">
                  <div class="col">
                    <h6 class="m-0 fw-semibold fs-15">Filtrar por:</h6>
                  </div>
                </div>
                <div class="app-search p-0 mt-3">
                  <div class="position-relative mb-2">
                    <input @keyup="filterCompaniesByName" type="text" class="form-control"
                      placeholder="Buscar empresa..." autocomplete="off" id="search-company-options">
                    <span class="mdi mdi-magnify search-widget-icon" />
                    <span class="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                      id="search-company-close-options" />
                  </div>
                </div>
              </div>

              <div class="p-2 pb-0" id="checkAllCompaniesContainer">
                <label
                  class="form-check mb-1 px-4 py-1 fs-14 border-top-0 border-start-0 border-end-0 border-dashed border cursor-pointer">
                  <input @change="selectAllCompanies" type="checkbox" v-model="checkAllCompanies"
                    class="form-check-input">
                  Marcar/Desmarcar tudo
                </label>
              </div>

              <SimpleBar data-simplebar style="max-height: 350px">
                <div class="p-2 pt-0">
                  <div class="">
                    <template v-for="company in displayableCompanies" :key="company.id_empresa">
                      <label v-if="company.match"
                        class="form-check mb-1 px-4 py-1 fs-14 border-top-0 border-start-0 border-end-0 border-dashed border cursor-pointer">
                        <input @change="saveCompaniesFilter" type="checkbox" v-model="company.checked"
                          class="form-check-input" :id="'company-check-' + company.id_empresa">
                        {{ company.nome }}
                      </label>
                    </template>
                  </div>
                </div>
              </SimpleBar>
            </div>
          </div>

          <div class="ms-1 d-flex align-items-center" v-if="runningJobs.length"
            @click="$store.commit('asyncJobs/showAllRunningJobs')">
            <button type="button" v-b-tooltip.hover.top="'Operações em andamento...'"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
              <div class="spinner-border text-success" style="width:20px;height:20px;" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>

        <div class="d-flex align-items-center">
          <div class="dropdown d-md-none topbar-head-dropdown header-item">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="bx bx-search fs-22" />
            </button>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-search-dropdown">
              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search ..."
                      aria-label="Recipient's username" />
                    <button class="btn btn-primary" type="submit">
                      <i class="mdi mdi-magnify" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="ms-1 header-item d-none d-sm-flex">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              data-toggle="fullscreen" @click="initFullScreen" title="Tela Cheia">
              <i class="bx bx-fullscreen fs-22" />
            </button>
          </div>

          <div class="ms-1 header-item d-none d-sm-flex">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              @click="updatePermissions" title="Atualizar Permissões">
              <i class="mdi mdi-cloud-refresh fs-22" />
            </button>
          </div>

          <div class="ms-1 header-item d-none d-sm-flex">
            <button type="button" class=" btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
              @click="toggleDarkMode" title="Modo Noturno">
              <i class="bx bx-moon fs-22" />
            </button>
          </div>

          <div class="dropdown topbar-head-dropdown ms-1 header-item">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" title="Notificações">
              <i class="bx bx-bell fs-22" />
            </button>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-notifications-dropdown">
              <div class="dropdown-head bg-primary bg-pattern rounded-top">
                <div class="p-3">
                  <div class="row align-items-center">
                    <div class="col">
                      <h6 class="m-0 fs-16 fw-semibold text-white">
                        Notificações
                      </h6>
                    </div>
                  </div>
                </div>

                <div class="px-2 pt-2">
                  <ul class="nav nav-tabs dropdown-tabs nav-tabs-custom" data-dropdown-tabs="true"
                    id="notificationItemsTab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" data-bs-toggle="tab" href="#actions-noti-tab" role="tab"
                        aria-selected="true" @click.capture.stop>
                        Ações
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab-content" id="notificationItemsTabContent">
                <div class="px-3">
                  <div class="form-check mb-1 mt-2">
                    <input class="form-check-input" type="checkbox" id="dontShowAnymore2" :checked="alwaysShowActions"
                      @change="setAlwaysShowActions" @click.capture.stop>
                    <label class="form-check-label fs-11 fw-medium text-primary" for="dontShowAnymore2"
                      @click.capture.stop>
                      Sempre exibir na tela
                    </label>
                  </div>
                </div>

                <div class="tab-pane fade py-2 ps-2 show active" id="actions-noti-tab" role="tabpanel">
                  <SimpleBar data-simplebar style="max-height: 300px" class="pe-2">
                    <div v-for="job in allJobs" :key="job.id"
                      class="text-reset notification-item d-block dropdown-item position-relative">
                      <Job :supportClose="false" :job="job" />
                    </div>
                  </SimpleBar>
                </div>
              </div>
            </div>
          </div>

          <div class="">
            <button type="button" class="dropdown ms-sm-3 header-item topbar-user btn btn-ghost-secondary rounded-0"
              id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="d-flex align-items-center">
                <i class="bx bx-user fs-22" />
                <span class="d-none d-lg-inline-block ms-1 fw-medium user-name-text">
                  {{ sis_usuario.nome }}
                </span>
              </span>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <h6 class="dropdown-header">Bem vindo(a) {{ sis_usuario.nome.split(' ')[0] }}!</h6>
              <div class="dropdown-divider" />
              <span class="dropdown-item cursor-pointer" @click="toLockScreen">
                <i class="mdi mdi-lock-outline text-muted fs-16 align-middle me-1" />
                <span class="align-middle">
                  Bloquear Tela
                </span>
              </span>
              <a class="dropdown-item" href="/logout">
                <i class="mdi mdi-logout text-muted fs-16 align-middle me-1" />
                <span class="align-middle" data-key="t-logout">
                  Sair
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <BackgroundJobsNotification />
</template>